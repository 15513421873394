.vertically-centered-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-width: 500px) {
    .vertically-centered-form {
        min-width: 90%;
    }
}

.tracker__entries__table th:first-child,
.tracker__entries__table td:first-child {
    position: sticky;
    left: 0;
    background-color: #f1f2f4;
}

.tracker__entries__thead {
    position: sticky;
    left: 0;
    background-color: #f1f2f4;
}
